import React, { useState } from "react";
import $ from "jquery";
import "./request.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import "moment/locale/ko";
import { useNavigate } from "react-router-dom";

const apiPath = "https://admin.thinkthingthank.com/api";
const Request = () => {
  const navigate = useNavigate();
  const [fileNames, setFileNames] = useState([]);

  /* textarea 자동 높이 조절 */
  const handleResizeHeight = (e) => {
    e.target.style.height = "calc(18 / 1512 * 100vw)"; //height 초기화
    if ($(window).width() <= 767) {
      e.target.style.height = "calc(17 / 375 * 100vw)";
    }
    e.target.style.height = e.target.scrollHeight + "px";
  };

  /* 첨부파일 검증 */
  const validation = (obj) => {
    const fileTypes = ["application/pdf", "image/gif", "image/jpeg", "image/png", "image/bmp", "image/tif", "application/haansofthwp", "application/x-hwp"];
    if (obj.name.length > 100) {
      alert("파일명이 100자 이상인 파일은 제외되었습니다.");
      return false;
    } else if (obj.size > 10 * 1024 * 1024) {
      alert("최대 파일 용량인 10MB를 초과한 파일은 제외되었습니다.");
      return false;
    } else if (obj.name.lastIndexOf(".") == -1) {
      alert("확장자가 없는 파일은 제외되었습니다.");
      return false;
    } else if (!fileTypes.includes(obj.type)) {
      alert("첨부가 불가능한 파일은 제외되었습니다.");
      return false;
    } else if (fileNames.indexOf(obj.name) !== -1) {
      alert("같은 파일은 제외되었습니다.");
      return false;
    } else {
      return true;
    }
  };

  const addFile = (obj) => {
    var maxFileCnt = 10; // 첨부파일 최대 개수
    var attFileCnt = fileNames.length; // 기존 추가된 첨부파일 개수
    var remainFileCnt = maxFileCnt - attFileCnt; // 추가로 첨부가능한 개수
    var curFileCnt = obj.target.files.length; // 현재 선택된 첨부파일 개수
    var fileNameArr = [];

    // 첨부파일 개수 확인
    if (curFileCnt > remainFileCnt) {
      alert("첨부파일은 최대 " + maxFileCnt + "개 까지 첨부 가능합니다.");
    }

    for (var i = 0; i < Math.min(curFileCnt, remainFileCnt); i++) {
      const file = obj.target.files[i];

      // 첨부파일 검증
      if (validation(file)) {
        fileNameArr.push(file.name);
      } else {
        return;
      }
    }

    var sendForm = new FormData($("#sendForm")[0]);

    $.ajax({
      type: "post",
      async: true,
      url: apiPath + "/fileUpload",
      data: sendForm,
      //dataType: 'json',
      contentType: false,
      processData: false,
      beforeSend: function () {
        // 로딩바 html
        $(".loading").addClass("on");
      },
      success: function (data, textStatus, jqXHR) {
        $(".loading").removeClass("on");

        if (data != 1) {
          alert("업로드에 실패하였습니다.\n담당자에게 문의하세요.");
        }

        $("#file").val("");
      },
      error: function (jqXHR, textStatus, errorThrown) {
        alert(jqXHR.responseJSON.messages.msg);
      },
    });

    setFileNames([...fileNames, ...fileNameArr]);
  };

  /* 첨부파일 삭제 */
  const deleteFile = (e) => {
    var name = $(e.currentTarget).prev().text();
    setFileNames(fileNames.filter((fileName) => fileName !== name));
  };

  /* 문의완료 페이지 변경 */
  const complete = (e) => {
    // $(".modal-popup").addClass("open");
    $(e.currentTarget).addClass("hover");

    $(".cursor").removeClass("hover hidden");
    $("header").removeClass("active");
    $(".routes").addClass("fadeOut active");
    $(".ani, .ani2").removeClass("active");

    setTimeout(() => {
      navigate("/confirm-message");

      window.scroll(0, 0);
      $(".routes").removeClass("fadeOut active");
      $(".routes").addClass("fadeIn active");

      setTimeout(() => {
        $(".routes").removeClass("fadeIn active");
      }, 300);
    }, 900);
  };

  const closePopup = (e) => {
    e.preventDefault();
    $(".modal-popup").removeClass("open");
    $(".sec3 .inner").removeClass("hover");

    window.location.reload();
  };

  const mailSend = (e) => {
    if (!$("#sendForm textarea[name='project_desc']").val()) {
      alert("프로젝트 의뢰 내용을 입력해주세요.");
      return;
    }

    if (!$("#sendForm textarea[name='project_date']").val()) {
      alert("프로젝트 일정을 입력해주세요.");
      return;
    }

    if (!$("#sendForm input[name='name']").val()) {
      alert("성함을 입력해주세요.");
      return;
    }

    if (!$("#sendForm input[name='company']").val()) {
      alert("소속을 입력해주세요.");
      return;
    }

    if (!$("#sendForm input[name='phone']").val()) {
      alert("연락처를 입력해주세요.");
      return;
    }

    if (!$("#sendForm input[name='email']").val()) {
      alert("이메일을 입력해주세요.");
      return;
    }

    if (!$("#sendForm input[name='budget']:checked").val()) {
      alert("프로젝트 예산범위를 선택해주세요.");
      return;
    }

    var sendForm = $("#sendForm").serialize();

    $.ajax({
      type: "post",
      async: true,
      url: apiPath + "/contractEmailSend",
      data: sendForm,
      beforeSend: function () {
        // 로딩바 html
        $(".loading").addClass("on");
      },
      success: function (data, textStatus, jqXHR) {
        $(".loading").removeClass("on");
        if (data == 1) {
          complete(e);
        } else {
          alert("의뢰 접수에 실패하였습니다.\n관리자에게 문의해주세요.");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $(".loading").removeClass("on");
        alert(jqXHR.responseJSON.messages.msg);
      },
    });
  };

  return (
    <ParallaxProvider>
      <div className="request">
        <div className="scroll-container">
          <div className="sec1">
            <div className="inner">
              <h1 className="ani">
                <span>Let’s Talk!</span>
              </h1>
              <div className="p-box-wrap">
                <div className="p-box step1">
                  <p className="ani">
                    <span>
                      We are think thing thank a New Experience <br />
                      Design Creator Group
                    </span>
                  </p>
                </div>
                <div className="p-box step2">
                  <p className="ani">
                    <span>
                      안녕하세요! <img src={`${process.env.PUBLIC_URL}/images/request_icon1.png`} alt="" className="img1" />
                      <br />
                      띵띵땡 크리에이티브 그룹에게
                      <br />
                      의뢰주시는 내용은 무엇인가요?
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="inner">
              <form id="sendForm" method="post" encType="multipart/form-data">
                <ul className="request-lists">
                  <li className="line-top ani">
                    <label>프로젝트 의뢰 내용</label>
                    <div className="input-box">
                      <textarea name="project_desc" placeholder="예) 브랜딩, 브랜드 기획 등 (상세하게 적어주시면 더욱 좋아요!)" onChange={handleResizeHeight} />
                    </div>
                  </li>
                  <li className="line-top ani">
                    <label>프로젝트 일정</label>
                    <div className="input-box">
                      <textarea name="project_date" placeholder="원하시는 일정 또는 마감 기한을 적어주세요" onChange={handleResizeHeight} />
                    </div>
                  </li>
                  <li className="line-top ani style1">
                    <label>프로젝트 예산범위</label>
                    <div className="input-box">
                      <ul className="radio-lists">
                        <li>
                          <input type="radio" name="budget" className="cursor-btn" value="1000만원 이상" />
                          <label className="radio">
                            <span>1000만원 이상</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="budget" className="cursor-btn" value="2000만원 이상" />
                          <label className="radio">
                            <span>2000만원 이상</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="budget" className="cursor-btn" value="3000만원 이상" />
                          <label className="radio">
                            <span>3000만원 이상</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="budget" className="cursor-btn" value="5000만원 이상" />
                          <label className="radio">
                            <span>5000만원 이상</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="budget" className="cursor-btn" value="미정" />
                          <label className="radio width">
                            <span>미정</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <ul className="request-lists">
                  <li className="line-top ani">
                    <label>담당자 정보</label>
                    <div className="input-box-wrap">
                      <div className="input-box">
                        <input type="text" name="name" placeholder="성함" />
                      </div>
                      <div className="input-box">
                        <input type="text" name="company" placeholder="소속" />
                      </div>
                      <div className="input-box">
                        <input type="tel" name="phone" placeholder="연락처" />
                      </div>
                      <div className="input-box">
                        <input type="email" name="email" placeholder="이메일" />
                      </div>
                    </div>
                  </li>
                  <li className="line-top ani">
                    <div className="label-box">
                      <label>참고자료</label>
                      <div className="file-input">
                        <span>파일선택</span>
                        <input multiple name="file[]" id="file" type="file" className="cursor-btn" onChange={addFile} />
                      </div>
                    </div>
                    <ul className="file-lists">
                      {fileNames.map((fileName) => (
                        <li key={fileName}>
                          <input type="hidden" name="filename[]" value={fileName} />
                          <span className="txt">{fileName}</span>
                          <span className="delete-file" onClick={deleteFile}></span>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </form>
            </div>
          </div>
          <div className="sec3" onClick={mailSend}>
            <div className="inner">
              <div className="arrow"></div>
              <div className="text">
                <span>문의하기</span>
              </div>
              <div className="icon">
                <img src={`${process.env.PUBLIC_URL}/images/request_icon3.png`} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="loading">
          <img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="" />
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default Request;
