import "../../sass/projectList.scss";
import Category from "../common/Category/Category";
import ProjectLists from "../common/ProjectLists/ProjectLists";
import Footer from "../common/Footer/Footer";

const WorkSocial = () => {
  return (
    <div className="work-social black">
      <div className="scroll-container">
        <div className="sec1 work-category-sec ani">
          <div className="inner">
            <h1 className="step1">
              <span>
                Love <i>li</i>fe, sharing g<i>oo</i>d
              </span>
            </h1>
            <h1 className="step2">
              <span>
                energy <i>to</i> make
              </span>
            </h1>
            <h1 className="step3">
              <span>
                <i>the</i> world m<i>ea</i>ningful
              </span>
            </h1>
            <div className="lb-txt">
              <span>SOCIAL WORK</span>
            </div>
            <div className="p-box">
              <p>
                <span>함께 세상을 의미있게 만들어가는 움직임에</span>
              </p>
              <p>
                <span>참여함으로써 사회와 환경에 긍정적인 임팩트를 주며</span>
              </p>
              <p>
                <span>다양한 모든 사람들이 행복해지는 커뮤니티와 문화,</span>
              </p>
              <p>
                <span>플랫폼을 만들고 있습니다.</span>
              </p>
            </div>
          </div>
          <div className="video-box">
            <div className="video">
              <iframe
                src="https://player.vimeo.com/video/767208035?h=ac27f4305d&controls=0&autopause=0&background=1"
                title="main_bg_social"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="sec2">
          <Category category="Social" />
        </div>
        <div className="sec3">
          <div className="inner">
            <ProjectLists category="Social"/>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WorkSocial;
