import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";

const Cursor = () => {
  const [isPc, setIsPc] = useState(false);
  const [position, setPosition] = useState({ x: -1000, y: -1000 });
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [hover, setHover] = useState(false);

  const onMouseMove = useCallback((e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  }, []);
  const onMouseDown = useCallback(() => {
    setClicked(true);
  }, []);
  const onMouseUp = useCallback(() => {
    setClicked(false);
  }, []);

  const onMouseEnter = useCallback(() => {
    setHidden(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHidden(true);
  }, []);

  const handleLinkHoverEvents = () => {
    document.querySelectorAll("a, button, .cursor-btn").forEach((el) => {
      el.addEventListener("mouseover", () => {
        setHover(true);
      });
      el.addEventListener("mouseout", () => {
        setHover(false);
      });
    });
  };

  const cursorClasses = classNames("cursor", {
    clicked: clicked,
    hover: hover,
    hidden: hidden,
  });

  useEffect(() => {
    let filter = "win16|win32|win64|mac|macintel";
    if (0 > filter.indexOf(navigator.platform.toLowerCase())) {
      setIsPc(false);
    } else {
      setIsPc(true);
    }
  }, [isPc]);

  useEffect(() => {
    handleLinkHoverEvents();
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.querySelector("body").addEventListener("mouseenter", onMouseEnter);
    document.querySelector("body").addEventListener("mouseleave", onMouseLeave);
  });

  return isPc ? (
    <div
      className={cursorClasses}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    />
  ) : (
    ""
  );
};

export default Cursor;
