import $ from "jquery";

const FixScrollCloud = (offsetY, windowWidth, isTouchDevice, num) => {
  let windowHeight = $(window).height();
  let cotentHeight = $(".scroll-content").length > 0 ? $(".scroll-content").outerHeight() - windowHeight : $(".scroll-container").outerHeight();
  let cloudHeight = $(".cloud-box").outerHeight();
  let bottom = 84;
  let paddingBottom = (windowWidth * 2.5) / 100;
  if (windowWidth <= 1200) {
    bottom = 67;
  } else if (windowWidth <= 767) {
    bottom = 60;
    paddingBottom = 0;
  }
  let calcbottom = paddingBottom + bottom;
  let cloudFixPosition = cotentHeight + cloudHeight - calcbottom;

  if (offsetY < cloudFixPosition) {
    if ($(".cloud-box").hasClass("fix-bottom")) {
      $(".cloud-box").removeClass("fix-bottom");
    }
  } else {
    if (!$(".cloud-box").hasClass("fix-bottom")) {
      $(".cloud-box").addClass("fix-bottom");
    }
  }
};

export default FixScrollCloud;
