import React, { useCallback, useEffect, useState } from "react";
import "./ourTeam.scss";
import axios from "axios";
import $ from "jquery";
import EmployeeLists from "../common/EmployeeLists/EmployeeLists";
//import EmployeeLists_C from "../common/EmployeeLists/EmployeeLists_C";
//import EmployeeLists_B from "../common/EmployeeLists/EmployeeLists_B";
//import EmployeeLists_S from "../common/EmployeeLists/EmployeeLists_S";
import Footer from "./../common/Footer/Footer";

const apiPath = "https://admin.thinkthingthank.com/api";
const OurTeam = (id) => {
  const [listS, setListS] = useState([]);
  const [listC, setListC] = useState([]);
  const [listB, setListB] = useState([]);

  const getPosts = async () => {
    var form = new FormData();

    const response = await axios.post(apiPath + "/getMemberCnt");
    let data = response.data;

    var totalEmp = 0;
    var c_emp = 0;
    var b_emp = 0;
    var s_emp = 0;

    setListS(data.memSRow);
    setListC(data.memCRow);
    setListB(data.memBRow);

    for (var i = 0; i < data.memCntArr.length; i++) {
      totalEmp += Number(data.memCntArr[i].memCnt);

      if (data.memCntArr[i].category == "B") {
        b_emp += Number(data.memCntArr[i].memCnt);
      } else if (data.memCntArr[i].category == "C") {
        c_emp += Number(data.memCntArr[i].memCnt);
      } else if (data.memCntArr[i].category == "S") {
        s_emp += Number(data.memCntArr[i].memCnt);
      }
    }

    $(".total-employee").text(totalEmp);
    $(".total-creative").text(c_emp);
    $(".total-business").text(b_emp);
    $(".total-social").text(s_emp);
  };
  useEffect(() => {
    getPosts();
  }, []);

  //useEffect( async () => {

  /*
        $.ajax({
          type: 'post',
          async: false,
          url: apiPath+'/getMemberCnt',
          data: {},
          dataType: 'json',
          success: function ( data, textStatus, jqXHR ) {
              console.log(data);
              var totalEmp = 0;
              var c_emp = 0;
              var b_emp = 0;
              var s_emp = 0;

              setListS(data.memSRow);
              setListC(data.memCRow);
              setListB(data.memBRow);

              for(var i = 0; i < data.memCntArr.length; i++) {
                  totalEmp += Number(data.memCntArr[i].memCnt);

                  if(data.memCntArr[i].category == 'B') {
                      b_emp += Number(data.memCntArr[i].memCnt);
                  } else if(data.memCntArr[i].category == 'C') {
                      c_emp += Number(data.memCntArr[i].memCnt);
                  } else if(data.memCntArr[i].category == 'S') {
                      s_emp += Number(data.memCntArr[i].memCnt);
                  }
              }

              $('.total-employee').text(totalEmp);
              $('.total-creative').text(c_emp);
              $('.total-business').text(b_emp);
              $('.total-social').text(s_emp);
          },
          error: function (jqXHR, textStatus, errorThrown) {
              alert( jqXHR.responseJSON.messages.msg );
          }
      });
      */
  //}, []);

  return (
    <div className="our-team">
      <div className="scroll-container">
        <div className="sec1">
          <h1 className="ani">
            <span>
              Who <span className="pd-font">we</span> are?
            </span>
          </h1>
        </div>
        <div className="sec2">
          <div className="inner">
            <div className="txt-box ani pc">
              <h2 className="step1">
                <span>
                  <span className="we-are">we are </span>
                  <span className="underline">think thing thank</span>
                  <sup>
                    <span className="pd-font">(</span>
                    <span className="total-employee">44</span>
                    <span className="pd-font">)</span>
                  </sup>
                </span>
              </h2>
              <h2 className="step2">
                <span>
                  a <i className="pd-font">new</i> experience
                </span>
              </h2>
              <h2 className="step3">
                <span>
                  creator <i className="pd-font">group</i>
                </span>
              </h2>
            </div>
            <div className="txt-box ani mo">
              <h2 className="step1">
                <span>we are</span>
              </h2>
              <h2 className="step2">
                <span>
                  <span className="underline">think thing thank</span>
                  <sup>
                    <span className="pd-font">(</span>
                    <span className="total-employee">44</span>
                    <span className="pd-font">)</span>
                  </sup>
                </span>
              </h2>
              <h2 className="step3">
                <span>
                  a <i className="pd-font">new</i> experience
                </span>
              </h2>
              <h2 className="step4">
                <span>
                  creator <i className="pd-font">group</i>
                </span>
              </h2>
            </div>
          </div>
          <div className="video-box ani">
            <div className="video">
              <div style={{ padding: "52.71% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/767208554?h=2d430b0a68&controls=0&autopause=0&background=1"
                  title="our_team"
                  style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="sec3">
          <div className="part">
            <div className="inner">
              <h2 className="ani">
                <span>
                  think
                  <sup>
                    Cr<i className="pd-font">ea</i>tive
                    <span className="num">
                      <span className="pd-font">(</span>
                      <span className="total-creative">18</span>
                      <span className="pd-font">)</span>
                    </span>
                  </sup>
                </span>
              </h2>
              <div className="pc p-box line-top line-bottom ani">
                <p>
                  <span>Be freed, with endless imagination</span>
                </p>
                <p>
                  <span>To think in different perspective</span>
                </p>
              </div>
              <div className="mo p-box line-top line-bottom ani">
                <p>
                  <span>Be freed, with endless</span>
                </p>
                <p>
                  <span>imagination To think</span>
                </p>
                <p>
                  <span>in different perspective</span>
                </p>
              </div>
            </div>
            <EmployeeLists category="C" />
          </div>
          <div className="part">
            <div className="inner">
              <h2 className="ani">
                <span>
                  thing
                  <sup>
                    Busi<i className="pd-font">ne</i>ss
                    <span className="num">
                      <span className="pd-font">(</span>
                      <span className="total-business">13</span>
                      <span className="pd-font">)</span>
                    </span>
                  </sup>
                </span>
              </h2>
              <div className="pc p-box line-top line-bottom ani">
                <p>
                  <span>Different together, we gather</span>
                </p>
                <p>
                  <span>To make thing with good impact</span>
                </p>
              </div>
              <div className="mo p-box line-top line-bottom ani">
                <p>
                  <span>Different together,</span>
                </p>
                <p>
                  <span>we gather To make</span>
                </p>
                <p>
                  <span>thing with good impact</span>
                </p>
              </div>
            </div>
            <EmployeeLists category="B" />
          </div>
          <div className="part">
            <div className="inner">
              <h2 className="ani">
                <span>
                  thank
                  <sup>
                    <i className="pd-font">So</i>cial
                    <span className="num">
                      <span className="pd-font">(</span>
                      <span className="total-social">13</span>
                      <span className="pd-font">)</span>
                    </span>
                  </sup>
                </span>
              </h2>
              <div className="pc p-box line-top line-bottom ani">
                <p>
                  <span>Love life, sharing good energy</span>
                </p>
                <p>
                  <span>To make the world meaningful</span>
                </p>
              </div>
              <div className="mo p-box line-top line-bottom ani">
                <p>
                  <span>Love life, sharing</span>
                </p>
                <p>
                  <span>good energy To make</span>
                </p>
                <p>
                  <span>the world meaningful</span>
                </p>
              </div>
            </div>
          </div>
          <EmployeeLists category="S" />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OurTeam;
