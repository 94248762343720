import React, { useEffect, useState } from "react";
import "../../../sass/projectList.scss";
import { DelayLink } from "./../Header/DelayLink";
import $ from "jquery";
import { useParams } from "react-router";
import axios from "axios";
import { useLocation } from "react-router-dom";

const apiPath = "https://admin.thinkthingthank.com/api";

const ProjectLists = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [categoryName, setCategoryName] = useState();
  const [category, setCategory] = useState();
  const [portList, setPortList] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [filter, setFilter] = useState();

  useEffect(() => {
    if (location.search) {
      var searchStr = location.search.replace("?", "");
      var searchArr = searchStr.split("&");

      var searchValArr = [];
      for (var i = 0; i < searchArr.length; i++) {
        searchValArr[i] = searchArr[i].split("=");

        if (searchValArr[i][0] == "filter" && searchValArr[i][1] != '') {
          setFilter(searchValArr[i][1]);
        } else {
          setFilter('');
        }
      }
    }
  }, [location, pathname]);

  useEffect(() => {
    if (location.search) {
      var searchStr = location.search.replace("?", "");
      var searchArr = searchStr.split("&");

      var searchValArr = [];
      for (var i = 0; i < searchArr.length; i++) {
        searchValArr[i] = searchArr[i].split("=");

        if (searchValArr[i][0] == "filter" && searchValArr[i][1] != '') {
          setFilter(searchValArr[i][1]);
        } else {
          setFilter('');
        }
      }
    }

    setCategory(props.category);

    const getPosts = async () => {
      var form = new FormData();
      form.append("category", category);
      form.append("filter", filter);

      const response = await axios.post(apiPath + "/getPortfolioList", form);
      var data = response.data;
      setPortList(data.portRow);
      setNoticeData(data.noticeData);
    };
    if(category !== undefined) {
        getPosts();
    }
}, [category, filter]);

  return (
    <div className="project-cont">
      {noticeData.length != 0 ? (
        <div className="big-thumbnail">
          <DelayLink to={`/work/${noticeData.idx}`} className="view">
            <div className="project-text-box ani">
              <ul>
                <li>
                  <span className="ko" dangerouslySetInnerHTML={{ __html: noticeData.title }}></span>
                </li>
                {/*
                <li>
                  <span>{noticeData.location}</span>
                </li>
                */}
                <li>
                  <span>{noticeData.filterStr}</span>
                </li>
              </ul>
            </div>
            <div className="project-img-box ani">
                {noticeData.notice_video !== null && noticeData.notice_video !== "" && noticeData.notice_video !== "undefined" ? (
                    <div className="video">
                      <iframe
                        src={`${noticeData.notice_video}&controls=0&autopause=0&background=1`}
                        title="title1"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                ) : (
                    <img src={`https://admin.thinkthingthank.com${noticeData.notice_img}`} alt="" />
                )}
            </div>
          </DelayLink>
        </div>
      ) : null}
      <div className="project-lists">
        <ul>
          {portList.map((item, index) => (
            <li key={index} className={`${item.thumb_class}`}>
              <DelayLink to={`/work/${item.idx}`} className="view">
                <div className="project-text-box ani">
                  <ul>
                    <li>
                      <span className="en" dangerouslySetInnerHTML={{ __html: item.title }}></span>
                    </li>
                    {/*
                    <li>
                      <span>{item.location}</span>
                    </li>
                    */}
                    <li>
                      <span>{item.filterStr}</span>
                    </li>
                  </ul>
                </div>
                <div className="project-img-box ani">
                    {item.thumb_video !== null && item.thumb_video !== "" && item.thumb_video !== "undefined" ? (
                        <div className="video">
                          <iframe
                            src={`${item.thumb_video}&controls=0&autopause=0&background=1`}
                            title="title1"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                    ) : (
                        <img src={`https://admin.thinkthingthank.com${item.thumb_img}`} alt="" />
                    )}
                </div>
              </DelayLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectLists;
