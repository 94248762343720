import React from "react";
import { Helmet } from "react-helmet-async";
import Request from "../components/request/Request";

const RequestPage = () => {
  return (
    <>
      <Helmet>
        <title>Request | thing think thank</title>
      </Helmet>
      <Request />
    </>
  );
};

export default RequestPage;
