import React from "react";
import { Helmet } from "react-helmet-async";
import Contact from "./../components/contact/Contact";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact | thing think thank</title>
      </Helmet>
      <Contact />
    </>
  );
};

export default ContactPage;
