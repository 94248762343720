import "./intro.scss";

const Intro = () => {
  return (
    <div className="cover">
      <div className="cover-wrap">
        <div className="cont-wrap">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="text">
            <h1>
              <span>think thing thank Creative Group</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
