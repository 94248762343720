import React, { useCallback } from "react";
import "./header.scss";
import $ from "jquery";
import DelayLink from "./DelayLink";

const Header = () => {
  const clickMenuBtn = useCallback(() => {
    $("header").toggleClass("active");
  }, []);

  return (
    <header>
      <div className="hide">
        <img src={`${process.env.PUBLIC_URL}/images/contact_bg.png`} alt="" />
        <img src={`${process.env.PUBLIC_URL}/images/contact_img1.png`} alt="" />
        <img src={`${process.env.PUBLIC_URL}/images/contact_img2.png`} alt="" />
        <iframe
          src="https://player.vimeo.com/video/767208325?h=bf8ba6fbc7&controls=0&autopause=0&background=1"
          title="work_movement"
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="header-wrap">
        <h1 className="logo">
          <DelayLink to="/" className="logo-link">
            <img src={`${process.env.PUBLIC_URL}/images/logo_w.png`} alt="thing think thank" className="white-logo" />
            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="thing think thank" className="black-logo" />
          </DelayLink>
        </h1>
        <nav className="pc">
          <ul>
            <li>
              <DelayLink to="/about">About</DelayLink>
            </li>
            <li>
              <DelayLink to="/our-team">Our Team</DelayLink>
            </li>
            <li>
              <DelayLink to="/work">Work</DelayLink>
            </li>
            <li>
              <DelayLink to="/archive">Archive</DelayLink>
            </li>
            <li>
              <DelayLink to="/contact">Contact</DelayLink>
            </li>
          </ul>
        </nav>
        <button className="menu-btn" onClick={clickMenuBtn}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <nav className="mo">
        <ul>
          <li>
            <DelayLink to="/about">About</DelayLink>
          </li>
          <li>
            <DelayLink to="/our-team">Our Team</DelayLink>
          </li>
          <li>
            <DelayLink to="/work">Work</DelayLink>
          </li>
          <li>
            <DelayLink to="/archive">Archive</DelayLink>
          </li>
          <li>
            <DelayLink to="/contact">Contact</DelayLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default React.memo(Header);
