import "../../sass/projectList.scss";
import Category from "../common/Category/Category";
import ProjectLists from "../common/ProjectLists/ProjectLists";
import Footer from "../common/Footer/Footer";

const WorkBusiness = () => {
  return (
    <div className="work-business black">
      <div className="scroll-container">
        <div className="sec1 work-category-sec ani">
          <div className="inner">
            <h1 className="step1">
              <span>
                Different t<i>o</i>gether,
              </span>
            </h1>
            <h1 className="step2">
              <span>
                <i>we</i> gather to make <i>th</i>ing
              </span>
            </h1>
            <h1 className="step3">
              <span>
                with g<i>oo</i>d impact
              </span>
            </h1>
            <div className="lb-txt">
              <span>BUSINESS WORKS</span>
            </div>
            <div className="p-box">
              <p>
                <span>새로운 경험을 창출하는 의미있는 순간과 감도있는</span>
              </p>
              <p>
                <span>라이프스타일을 통해 긍정적인 이슈들을 만듭니다.</span>
              </p>
              <p>
                <span>이런 새로운 비즈니스들을 통해 지속가능하고</span>
              </p>
              <p>
                <span>좋은 경제에 대한 새로운 기준을 만들고 있습니다.</span>
              </p>
            </div>
          </div>
          <div className="video-box">
            <div className="video">
              <iframe
                src="https://player.vimeo.com/video/767207757?h=3250432001&controls=0&autopause=0&background=1"
                title="main_bg_business"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="sec2">
          <Category category="Business"/>
        </div>
        <div className="sec3">
          <div className="inner">
            <ProjectLists category="Business"/>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WorkBusiness;
