import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "./contact.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import moment from "moment";
import "moment/locale/ko";
import { useInterval } from "react-use";
import Footer from "./../common/Footer/Footer";
import Scrollbar from "smooth-scrollbar";
import { DelayLink } from "./../common/Header/DelayLink";

const Contact = () => {
  const [seconds, setSeconds] = useState();
  const [year, setYear] = useState();
  const bg = useRef();
  const sec1Cont = useRef();

  const onScroll = ({ offsetY }) => {
    const y = !!offsetY ? offsetY : window.scrollY;
    $(bg.current).css({ transform: `translateY(${y / 3}px)` });
    $(sec1Cont.current).css({ transform: `translateY(${y / 8}px)` });
  };

  const options = {
    damping: 0.07,
  };

  useEffect(() => {
    const scrollbar = Scrollbar.init(document.querySelector(".scroll-container"), options);
    scrollbar.addListener(({ offset }) => {
      onScroll({ offsetY: offset.y });
    });

    window.addEventListener("scroll", onScroll);
    setYear(new Date().getFullYear());

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  useInterval(() => {
    const now = moment();
    const currentTime = now.format("HH:mm:ss");
    setSeconds(currentTime);
  }, 1000);

  return (
    <ParallaxProvider>
      <div className="contact">
        <div className="scroll-container">
          <div className="sec1">
            <div className="bg ani" ref={bg}></div>
            <div className="inner">
              <h1 className="ani">
                <span>
                  C<i className="pd-font">on</i>tact
                </span>
              </h1>
            </div>
            <div className="parallax-box" ref={sec1Cont}>
              <div className="img1 ani2">
                <span>
                  <span className="img"></span>
                  <div className="txt">
                    <span>
                      EXPAND THE WORLD WITH
                      <br />G<i className="pd-font">OO</i>D CONTENTS & DESIGN
                    </span>
                  </div>
                </span>
              </div>
              <div className="img2 ani2">
                <span>
                  <div className="txt top">
                    <span>
                      THINK THING THANK
                      <br />
                      MAKING G<i className="pd-font">OO</i>D M<i className="pd-font">O</i>VEMENT
                    </span>
                  </div>
                  <span className="img"></span>
                  <div className="txt bottom">
                    <span>2020 - NOW({year})</span>
                  </div>
                </span>
              </div>
              <div className="white-txt">
                <div className="txt ani2">
                  <span>
                    이 세상이 <span className="noto-font">(우리)</span>가<br />
                    원하는 모습이 아니라면
                    <br />
                    만들어보자
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="inner">
              <ul className="contact-lists">
                <li className="ani">
                  <div className="title">
                    <h3>
                      <span>
                        W<i className="pd-font">h</i>ere <i className="pd-font">we</i> are <span className="icon"></span>
                      </span>
                    </h3>
                  </div>
                  <ul className="content">
                    <li>
                      <p className="ptd-font address">서울시 영등포구 양평로30길 14 1204호 (세종앤까뮤스퀘어)</p>
                    </li>
                    <li>
                      <p className="address">
                        14, Yangpyeong-ro 30-gil, <br className="mo" /> Yeongdeungpo-gu, Seoul, <br className="mo" />
                        Republic of Korea (07202)
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="line-top ani">
                  <div className="title">
                    <h3>
                      <span>
                        Work <span className="icon"></span> with <i className="pd-font">us</i>!
                      </span>
                    </h3>
                  </div>
                  <ul className="content">
                    <li>
                      <p>WORK TIME 09:30 - 18:30 ({seconds})</p>
                    </li>
                    <li>
                      <a href="mailto:think.thing.thank@gmail.com" className="b_line">
                        think.thing.thank@gmail.com
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="line-top ani">
                  <div className="title">
                    <h3>
                      <span>
                        Join <i className="pd-font">us</i>! <span className="icon"></span>
                      </span>
                    </h3>
                  </div>
                  <ul className="content">
                    <li>
                      <a
                        href="https://thinkthingthank-work.notion.site/think-thing-thank-Recruit-f6659d174db24a8281408221331a9600"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="b_line"
                      >
                        NOW HIRING
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="line-top ani">
                  <div className="title">
                    <h3>
                      <span>
                        <span className="icon"></span>Fo
                        <i className="pd-font">ll</i>ow
                      </span>
                    </h3>
                  </div>
                  <ul className="content">
                    <li>
                      <p>Instagram</p>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/think.thing.thank/" target="_blank" rel="noopener noreferrer" className="b_line">
                        @think.thing.thank
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <DelayLink to="../request" className="arrow-txt ani">
                <span className="text-wrap">
                  <span className="txt">Let’s Talk!</span>
                  <span className="bracket">
                    <span className="pd-font">(</span>
                    <span className="arrow"></span>
                    <span className="pd-font">)</span>
                  </span>
                </span>
              </DelayLink>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default Contact;
