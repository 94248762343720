import React from "react";
import { Helmet } from "react-helmet-async";
import Archive from "./../components/archive/Archive";

const ArchivePage = () => {
  return (
    <>
      <Helmet>
        <title>Archive | thing think thank</title>
      </Helmet>
      <Archive />
    </>
  );
};

export default ArchivePage;
