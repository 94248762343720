import "../../sass/projectList.scss";
import Category from "../common/Category/Category";
import ProjectLists from "../common/ProjectLists/ProjectLists";
import Footer from "../common/Footer/Footer";

const WorkCreative = () => {
  return (
    <div className="work-creative black">
      <div className="scroll-container">
        <div className="sec1 work-category-sec ani">
          <div className="inner">
            <h1 className="step1">
              <span>
                Be fr<i>ee</i>d, with endless
              </span>
            </h1>
            <h1 className="step2">
              <span>
                imagination to <i>th</i>ink in
              </span>
            </h1>
            <h1 className="step3">
              <span>
                differ<i>ent</i> perspective
              </span>
            </h1>
            <div className="lb-txt">
              <span>CREATIVE WORKS</span>
            </div>
            <div className="p-box">
              <p>
                <span>자유롭게 상상하고 다양한 관점으로 바라봄으로써</span>
              </p>
              <p>
                <span>창의적인 기획과 의미있는 디자인으로 특별한</span>
              </p>
              <p>
                <span>경험과 스토리를 만들어내고 모두에게 좋은 영감을</span>
              </p>
              <p>
                <span>줄 수 있는 프로젝트들을 창출하고 있습니다.</span>
              </p>
            </div>
          </div>
          <div className="video-box">
            <div className="video">
              <iframe
                src="https://player.vimeo.com/video/767207677?h=e2360c7d17&controls=0&autopause=0&background=1"
                title="main_bg_creative"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="sec2">
          <Category category="Creative" />
        </div>
        <div className="sec3">
          <div className="inner">
            <ProjectLists category="Creative"/>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WorkCreative;
