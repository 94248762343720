import React, { useCallback, useEffect, useState } from "react";
import Swiper from "react-id-swiper";
import axios from "axios";
import "swiper/swiper.scss";
import "./employeeLists.scss";
import $ from "jquery";
import { DelayLink } from "./../Header/DelayLink";
import { Autoplay, Swiper as RealSwiper } from "swiper";
//import { Swiper, SwiperSlide } from "swiper/react";

const apiPath = "https://admin.thinkthingthank.com/api";

const EmployeeLists = (category) => {
  const [items, setItems] = useState([]);
  const [swiper, setSwiper] = useState();

  const params = {
    slidesPerView: "auto",
    loop: false,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    speed: 1500,
    rebuildOnUpdate: true,
  };

  const cursorEvent = useCallback((e) => {
    $(".cursor").css({ left: e.clientX, top: e.clientY });
  }, []);

  useEffect(() => {
    document.addEventListener("pointermove", cursorEvent);
    return () => {
      document.removeEventListener("pointermove", cursorEvent);
    };
  });

  RealSwiper.use([Autoplay]);

  useEffect(() => {
    const getPosts = async () => {
      var form = new FormData();
      form.append("category", category.category);

      const response = await axios.post(apiPath + "/getMemberList", form);
      setItems(response.data.memRow);
      var mySwiper = document.querySelector(".swiper-container").swiper;
      setSwiper(mySwiper);
      /*
        if (typeof swiper === 'object') {
            swiper.autoplay.start();
        }
        */
    };

    getPosts();
  }, []);

  return (
    <div className="employeeLists ani2">
      <div className="react-swiper">
        <Swiper {...params}>
          {items.map((item, index) => (
            <div key={index}>
              <DelayLink to={`/our-team/${item.name}`}>
                <div className="img-box" style={{ backgroundColor: item.bg_code }}>
                  <img src={`https://admin.thinkthingthank.com${item.list_img}`} alt="" />
                </div>
                <div className="text-box">
                  <b className="name">{item.name}</b>
                  <span className="job">{item.type1}</span>
                </div>
              </DelayLink>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default React.memo(EmployeeLists);
