import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import $ from "jquery";
import MainPage from "./pages/MainPage";
import Header from "./components/common/Header/Header";
import AboutPage from "./pages/AboutPage";
import OurTeamPage from "./pages/OurTeamPage";
import WorkPage from "./pages/WorkPage";
import ArchivePage from "./pages/ArchivePage";
import ContactPage from "./pages/ContactPage";
import RequestPage from "./pages/RequestPage";
import Intro from "./components/common/Intro/Intro";
import WorkViewPage from "./pages/WorkViewPage";
import WorkCreativePage from "./pages/WorkCreativePage";
import WorkBusinessPage from "./pages/WorkBusinessPage";
import WorkSocialPage from "./pages/WorkSocialPage";
import OurTeamPersonalPage from "./pages/OurTeamPersonalPage";
import Cursor from "./components/common/Cursor/Cursor";
import Cloud from "./components/common/Cloud/Cloud";
import PassingPage from "./components/common/PassingPage/PassingPage";
import FixScrollCloud from "./lib/FixScrollCloud";
import Scrollbar from "smooth-scrollbar";
import isTouchDevice from "./lib/isTouchDevice";
import ConfirmMessagePage from "./pages/ConfirmMessagePage";

function App() {
  const [isInit, setIsInit] = useState(true);
  const location = useLocation();
  const pathname = location.pathname;
  let offsetY;

  const setPropertyVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const resizingEvent = () => {
    $(".ani, .ani2").addClass("not");
  };

  const afterResizingEvent = () => {
    $(".ani, .ani2").removeClass("not");
  };

  const scrollAnimation = () => {
    if (isTouchDevice() === false) {
      $(".ani, .ani2").each(function () {
        const aniPoint = $(this).offset().top;
        const scrollBottom = $(window).height() * 0.85;

        if (scrollBottom > aniPoint) {
          if (!$(this).hasClass("active")) {
            $(this).addClass("active");
          }
        }
      });
    } else {
      $(".ani, .ani2").each(function () {
        const aniPoint = $(this).offset().top + $(window).height() / 15;
        const scrollBottom = $(window).scrollTop() + $(window).height();
        if (scrollBottom > aniPoint) {
          if (!$(this).hasClass("active")) {
            $(this).addClass("active");
          }
        }
      });

      const scrollBottom = $(window).scrollTop() + $(window).height();
      offsetY = scrollBottom;
      FixScrollCloud(scrollBottom, $(window).width(), isTouchDevice(), 1);
    }
  };

  useEffect(() => {
    $(document).on("keydown", function (e) {
      if (e.key === "F12") {
        e.preventDefault();
        e.returnValue = false;
      }
    });

    $("body").addClass("overflow");
    if (pathname !== "/" && isTouchDevice() !== false) {
      $("body").removeClass("overflow");
    }
    setPropertyVh();
    setTimeout(() => {
      scrollAnimation();
    }, 100);
    window.addEventListener("resize", setPropertyVh);
    window.addEventListener("resize", resizingEvent);
    window.addEventListener("scroll", scrollAnimation);
    window.addEventListener("resize", function () {
      FixScrollCloud(offsetY, $(window).width(), isTouchDevice(), 2);
      $(".cloud-box").removeClass("fix-bottom");
    });

    let doit;
    window.addEventListener("resize", () => {
      clearTimeout(doit);
      doit = setTimeout(afterResizingEvent, 100);
    });
  });

  const options = {
    damping: 0.07,
  };

  useEffect(() => {
    if (pathname !== "/" && isTouchDevice() === false) {
      const scrollbar = Scrollbar.init(document.querySelector(".scroll-container"), options);
      scrollbar.addListener(({ offset }) => {
        offsetY = offset.y;
        FixScrollCloud(offset.y, $(window).width(), isTouchDevice(), 3);

        scrollAnimation();
      });
    }

    if (pathname !== "/" && isTouchDevice() !== false) {
      $("body").removeClass("overflow");
    }

    if (pathname === "/") {
      Scrollbar.destroy(document.querySelector(".scroll-container"));
    }

    if (pathname !== "/work-creative" && pathname !== "/work-business" && pathname !== "/work-social") {
      $(".routes").removeClass("black-page");
    }

    if (isInit) {
      pathname === "/" ? $("#root").addClass("index") : $("#root").removeClass("index");
      setIsInit(false);
    }

    if (pathname === "/request" || pathname === "/confirm-message") {
      $(".cloud-box").fadeOut();
    } else {
      $(".cloud-box").show();
    }

    if (pathname === "/" || pathname === "/request" || pathname === "/confirm-message") {
      $(".cloud-box").addClass("main-fix-bottom");
      $(".cloud-box").removeClass("fix-bottom");
    } else {
      $(".cloud-box").removeClass("main-fix-bottom");
    }

    return () => {
      Scrollbar.destroy(document.querySelector(".scroll-container"));
    };
  }, [pathname]);

  return (
    <>
      <Header />
      <Intro />
      <PassingPage />
      <div className="routes">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/our-team" element={<OurTeamPage />} />
          <Route path="/our-team/:id" element={<OurTeamPersonalPage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/work/:id" element={<WorkViewPage />} />
          <Route path="/work-creative" element={<WorkCreativePage />} />
          <Route path="/work-business" element={<WorkBusinessPage />} />
          <Route path="/work-social" element={<WorkSocialPage />} />
          <Route path="/archive" element={<ArchivePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/request" element={<RequestPage />} />
          <Route path="/confirm-message" element={<ConfirmMessagePage />} />
        </Routes>
      </div>
      <Cursor />
      <Cloud />
    </>
  );
}

export default App;
