import React from "react";
import "moment/locale/ko";

const ConfirmMessage = () => {
  return (
    <div className="confirmMessage">
      <div className="scroll-container"></div>
      <div className="modal-popup style2 open">
        <div className="inner">
          <h1>
            <span>DONE!</span>
            <img src={`${process.env.PUBLIC_URL}/images/request_popup_icon.png`} alt="" />
          </h1>
          <div className="p-box">
            <p className="step1">
              문의주셔서 감사합니다!
              <br />
              빠르게 확인 후 연락드릴게요 :)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMessage;
