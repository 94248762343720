import React from "react";
import { Helmet } from "react-helmet-async";
import OurTeamPersonal from "./../components/ourTeamPersonal/OurTeamPersonal";

const OurTeamPersonalPage = () => {
  return (
    <>
      <Helmet>
        <title>Our Team Personal | thing think thank</title>
      </Helmet>
      <OurTeamPersonal />
    </>
  );
};

export default OurTeamPersonalPage;
