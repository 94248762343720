import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./ourTeamPersonal.scss";
import $ from "jquery";
import { DelayLink } from "./../common/Header/DelayLink";
import { useParams } from "react-router";
import Footer from "./../common/Footer/Footer";

const apiPath = "https://admin.thinkthingthank.com/api";

let moreViewCnt = 0;
let moreCnt = 0;

const OurTeamPersonal = () => {
  const [memData, setMemData] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [portfolioCnt, setPortfolioCnt] = useState([]);
  const [careers, setCareers] = useState([]);
  const [type2, setType2] = useState([]);
  const params = useParams();

  useEffect(() => {
    const getPosts = async () => {
      var form = new FormData();
      form.append("name", params.id);

      const response = await axios.post(apiPath + "/getMemberData", form);
      var data = response.data;
      setMemData(data.memData);
      setPortfolios(data.portfolioRow);
      setPortfolioCnt(data.portfolioCnt);
      setCareers(data.memData.careerArr);
      setType2(data.memData.type2Arr);

      moreCnt = Math.ceil(data.portfolioCnt / 8);

      if (moreCnt > 1) {
        moreViewCnt = 1;
      } else {
        moreViewCnt = 0;
        $(".moreBtn").hide();
      }
    };

    getPosts();
  }, []);

  const getPostsPortfolio = async () => {
    const response = await axios.get(apiPath + "/getPortfolioAddList?cnt=" + moreViewCnt + "&name=" + params.id);

    var data = response.data;

    var mergeList = [];

    mergeList = portfolios.concat(data.portfolioRow);

    setPortfolios(mergeList);
    moreViewCnt++;

    if (moreCnt == moreViewCnt) {
      $(".moreBtn").hide();
    }
  };

  return (
    <div className="our-team-personal">
      <div className="fixed-text pc">
        <div className="inner">
          <h2 className="ani">
            <span>{memData.name}</span>
          </h2>
          <div className="p-box ani" dangerouslySetInnerHTML={{ __html: memData.intro }}></div>
        </div>
      </div>
      <div className="wrap">
        <div className="scroll-container">
          <div className="sec1" style={{ backgroundColor: memData.bg_code }}>
            <div className="fixed-text mo">
              <div className="inner">
                <h2 className="ani">
                  <span>{memData.name}</span>
                </h2>
                <div className="p-box ani" dangerouslySetInnerHTML={{ __html: memData.intro }}></div>
              </div>
            </div>
            <div className="inner">
              <div className="imoji ani">
                <img src={`https://admin.thinkthingthank.com${memData.detail_img}`} alt="" />
              </div>
              <div className="carrer-box line-top ani">
                <ul className="carrer-lists ani">
                  {careers.map((item, index) => (
                    <li key={index}>
                      <span className="txt-wrap">
                        <span className="campany">{item.careerName}</span>
                        <span className="year">{item.careerYear}</span>
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="field-lists">
                  <ul>
                    {type2.map((item, index) => (
                      <li key={index} className="ani">
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="inner">
              <div className="cont-wrap">
                <h2 className="ani">
                  <span>
                    <i className="pd-font">Pro</i>ject Experience
                  </span>
                </h2>
                <div className="project-experience line-top ani">
                  <ul>
                    {portfolios.map((item, index) => (
                      <li key={index} className="line-top ani">
                        <DelayLink to={`/work/${item.idx}`}>
                          <div className="text-box">
                            <ul>
                              <li>
                                <span>
                                  <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                  <span className="arrow-txt">
                                    <span className="arrow">
                                      <span className="step1"></span>
                                      <span className="step2"></span>
                                    </span>
                                  </span>
                                </span>
                              </li>
                              {/*
                                  <li>
                                    <span>{item.location}</span>
                                  </li>
                                  */}
                              {/*
                              <li>
                                <span className="pd-font">{item.filterStr}</span>
                              </li>
                              */}
                            </ul>
                          </div>
                        </DelayLink>
                        <div className={`img-box ` + item.thumb_class}>
                          <DelayLink to={`/work/${item.idx}`}>
                            {item.thumb_video !== null && item.thumb_video !== "" && item.thumb_video !== "undefined" ? (
                              <div className="video">
                                <iframe
                                  src={`${item.thumb_video}&controls=0&autopause=0&background=1`}
                                  title="title1"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            ) : (
                              <img src={`https://admin.thinkthingthank.com${item.thumb_img}`} alt="" />
                            )}
                          </DelayLink>
                        </div>
                        <span className="year">
                          <span>{item.year}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="view-more ani moreBtn">
                    <button onClick={getPostsPortfolio}>
                      <span className="pd-font">(</span>
                      View m<i className="pd-font">o</i>re
                      <span className="pd-font">)</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OurTeamPersonal;
