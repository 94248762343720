import $ from "jquery";
import { DelayLink } from "./../Header/DelayLink";

const Cloud = () => {
  let timer;
  const onMouseEnter = (e) => {
    var cloud = $(e.currentTarget).find("a");
    var circle3Gif = $("<img src='/images/cloud_circle3.gif' alt='' class='circle3' />", {});

    timer = setTimeout(function () {
      cloud.append(circle3Gif);
      $(".circle3").attr("src", function () {
        return $(this).attr("src") + "?v=" + Math.random();
      });
    }, 200);
  };
  const onMouseLeave = (e) => {
    clearTimeout(timer);
    $(".circle3").fadeOut(200, function () {
      $(".circle3").remove();
    });
  };

  return (
    <div className="cloud-box" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <DelayLink to="../request">
        <img src={`${process.env.PUBLIC_URL}/images/cloud.gif`} alt="" className="cloud" />
      </DelayLink>
    </div>
  );
};

export default Cloud;
