import React from "react";
import { Helmet } from "react-helmet-async";
import Request from "../components/confirmMessage/ConfirmMessage";

const ConfirmMessagePage = () => {
  return (
    <>
      <Helmet>
        <title>Confirm MessagePage | thing think thank</title>
      </Helmet>
      <Request />
    </>
  );
};

export default ConfirmMessagePage;
