import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./nextProject.scss";
import "../../../sass/projectList.scss";
import { DelayLink } from "./../Header/DelayLink";
import $ from "jquery";
import { useParams } from 'react-router';

const apiPath = "https://admin.thinkthingthank.com/api";

const NextProject = ({title}) => {
  const params = useParams();
  const [prevData, setPrevData] = useState([]);
  const [portData, setPortData] = useState([]);
  const [id, setId] = useState(0);

  useEffect(() => {
      const getPosts = async () => {
          var form = new FormData();
          form.append('idx', params.id);

          const response = await axios.post(apiPath+'/getPortfolioData', form);
          var data = response.data;
          if(title == 'View') {
              setPortData(data.portData);
              setPrevData(data.nextData);
          } else {
              setPortData(data.prevData);
              setPrevData(data.nextData);
          }
      }

      getPosts();

  }, [params.id]);

  return (
    <div className="next-project">
      <div className="inner">
        <div className="flex-box line-top ani">
          <div className="txt-box">
            <h2 className="ani">
              <span>
                {title} <i>Pro</i>ject
              </span>
            </h2>
            <DelayLink to="/work">
              <span className="arrow-txt ani">
                <span className="txt-wrap">
                  <span className="pd-font">(</span>All <i className="pd-font">Pro</i>ject
                  <span className="arrow">
                    <span className="step1"></span>
                    <span className="step2"></span>
                  </span>
                  <span className="pd-font">)</span>
                </span>
              </span>
            </DelayLink>
          </div>
          <div className="project-lists">
            <ul>
                {
                  portData.length != 0 ?
                  <li className={`${portData.thumb_class}`}>
                    <DelayLink to={`/work/${portData.idx}`} className="view">
                      <div className="project-text-box ani">
                        <ul>
                          <li>
                            <span dangerouslySetInnerHTML={{ __html: portData.title }}></span>
                          </li>
                          <li>
                            <span>{ portData.filterStr }</span>
                          </li>
                        </ul>
                      </div>
                      <div className="project-img-box ani">
                          {portData.thumb_video !== null && portData.thumb_video !== "" && portData.thumb_video !== "undefined" ? (
                              <div className="video">
                                <iframe
                                  src={`${portData.thumb_video}&controls=0&autopause=0&background=1`}
                                  title="title1"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                          ) : (
                              <img src={`https://admin.thinkthingthank.com${portData.thumb_img}`} alt="" />
                          )}
                      </div>
                    </DelayLink>
                  </li>
                  : null
                }
                {
                  prevData.length != 0 ?
                  <li className={`${prevData.thumb_class}`}>
                    <DelayLink to={`/work/${prevData.idx}`} className="view">
                      <div className="project-text-box ani">
                        <ul>
                          <li>
                            <span dangerouslySetInnerHTML={{ __html: prevData.title }}></span>
                          </li>
                          <li>
                            <span>{ prevData.filterStr }</span>
                          </li>
                        </ul>
                      </div>
                      <div className="project-img-box ani">
                          {prevData.thumb_video !== null && prevData.thumb_video !== "" && prevData.thumb_video !== "undefined" ? (
                              <div className="video">
                                <iframe
                                  src={`${prevData.thumb_video}&controls=0&autopause=0&background=1`}
                                  title="title1"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                          ) : (
                              <img src={`https://admin.thinkthingthank.com${prevData.thumb_img}`} alt="" />
                          )}
                      </div>
                    </DelayLink>
                  </li>
                  : null
                }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextProject;
