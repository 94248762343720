import React, { useEffect, useState } from "react";
import "./category.scss";
import "../../../sass/projectList.scss";
import $ from "jquery";
import { useParams } from "react-router";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { DelayLink } from "../Header/DelayLink";

const apiPath = "https://admin.thinkthingthank.com/api";

const Category = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [categoryName, setCategoryName] = useState();
  const [category, setCategory] = useState();
  const [cCnt, setCCnt] = useState();
  const [bCnt, setBCnt] = useState();
  const [sCnt, setSCnt] = useState();
  const [allCnt, setAllCnt] = useState();
  const [filter, setFilter] = useState();

  useEffect(() => {
    if (location.search) {
      var searchStr = location.search.replace("?", "");
      var searchArr = searchStr.split("&");

      var searchValArr = [];
      for (var i = 0; i < searchArr.length; i++) {
        searchValArr[i] = searchArr[i].split("=");

        if (searchValArr[i][0] == "filter" && searchValArr[i][1] != '') {
          setFilter(searchValArr[i][1]);
        } else {
          setFilter('');
        }
      }
    }
}, [location, pathname]);

  const slideToggleCategory = () => {
    $(".category-lists").slideToggle(100);
    $(".all-project-btn").toggleClass("on");
  };

  useEffect(() => {
    setCategory(props.category);

    $(".category-lists li").each(function(){
        $(this).show();
    });

    switch(category) {
      case 'Creative':
        setCategoryName("Cr<i>ea</i>tive");
        $(".category-lists li").eq(0).hide();
        break;
      case 'Business':
        setCategoryName("Busi<i>ne</i>ss");
        $(".category-lists li").eq(1).hide();
        break;
      case 'Social':
        setCategoryName("<i>So</i>cial");
        $(".category-lists li").eq(2).hide();
        break;
      case '':
        setCategoryName("All <i>Project</i>");
        break;
    }

    const getPosts = async () => {
      var form = new FormData();
      form.append("category", category);
      form.append("filter", filter);

      const response = await axios.post(apiPath + "/getPortfolioCnt", form);
      var data = response.data;
      setCCnt(data.cCnt);
      setBCnt(data.bCnt);
      setSCnt(data.sCnt);

      if (category == "Creative") {
        setAllCnt(data.cCnt);
      } else if (category == "Business") {
        setAllCnt(data.bCnt);
      } else if (category == "Social") {
        setAllCnt(data.sCnt);
      } else {
        setAllCnt(data.allCnt);
      }
    };
    if(category !== undefined) {
        getPosts();
    }
}, [category, filter]);

  $(".filter-lists .invert-btn").removeClass("on");

  if (location.search === "?filter=all" || location.search === "") {
    $(".filter-lists .invert-btn.step1").addClass("on");
  } else if (location.search === "?filter=BI/BX") {
    $(".filter-lists .invert-btn.step2").addClass("on");
  } else if (location.search === "?filter=GUI") {
    $(".filter-lists .invert-btn.step3").addClass("on");
  } else if (location.search === "?filter=UX/SI") {
    $(".filter-lists .invert-btn.step4").addClass("on");
  } else if (location.search === "?filter=BDX/BM") {
    $(".filter-lists .invert-btn.step5").addClass("on");
  } else if (location.search === "?filter=PRODUCT") {
    $(".filter-lists .invert-btn.step6").addClass("on");
  } else if (location.search === "?filter=CONTENT/CAMPAIGN/PROMOTION/EXPERIENCE") {
    $(".filter-lists .invert-btn.step7").addClass("on");
  }

  function filterBtnToggle(e) {
    $(".filter-lists .invert-btn").removeClass("on");
    $(e.currentTarget).toggleClass("on");
  }

  return (
    <div className="category">
      <button className="all-project-btn line-top line-bottom ani" onClick={slideToggleCategory}>
        <div className="btn-wrap ani">
          <span>
            <span className="num">
              <span>
                <span className="pd-font">(</span>
                <span className="count">{allCnt}</span>
                <span className="pd-font">)</span>
              </span>
            </span>
            <h2>
              <span className="txt" dangerouslySetInnerHTML={{ __html: categoryName }}></span>
            </h2>
          </span>
        </div>
      </button>
      <ul className="category-lists">
        <li>
          <DelayLink to="/work-creative" className="line-bottom ani">
            <span className="num ani">
              <span>
                <span className="pd-font">(</span>
                <span className="count">{cCnt}</span>
                <span className="pd-font">)</span>
              </span>
            </span>
            <h2 className="ani">
              <div>
                <span className="txt">
                  Cr<i>ea</i>tive
                </span>
              </div>
            </h2>
            <p className="description ani">
              <span>
                <span>
                  자유로운 상상과 관점으로 만들어낸
                  <br />
                  창의적인 기획과 특별한 경험과 스토리
                  <br />
                  그리고 의미있는 디자인
                </span>
              </span>
            </p>
          </DelayLink>
        </li>
        <li>
          <DelayLink to="/work-business" className="line-bottom ani">
            <span className="num ani">
              <span>
                <span className="pd-font">(</span>
                <span className="count">{bCnt}</span>
                <span className="pd-font">)</span>
              </span>
            </span>
            <h2 className="ani">
              <div>
                <span className="txt">
                  Busi<i>ne</i>ss
                </span>
              </div>
            </h2>
            <p className="description ani">
              <span>
                <span>
                  디자인을 만드는 것에서 그치지 않고
                  <br />
                  지속적인 긍정적 영향이 발현되기 위한
                  <br />
                  지속가능성
                </span>
              </span>
            </p>
          </DelayLink>
        </li>
        <li>
          <DelayLink to="/work-social" className="line-bottom ani">
            <span className="num ani">
              <span>
                <span className="pd-font">(</span>
                <span className="count">{sCnt}</span>
                <span className="pd-font">)</span>
              </span>
            </span>
            <h2 className="ani">
              <div>
                <span className="txt">
                  <i>So</i>cial
                </span>
              </div>
            </h2>
            <p className="description ani">
              <span>
                <span>
                  사회 전체에 긍정적인 영향을 창출하고
                  <br />
                  나타낼 수 있기 위한 발걸음
                  <br />
                </span>
              </span>
            </p>
          </DelayLink>
        </li>
      </ul>
      <div className="filter line-bottom ani">
        <h4>Filter</h4>
        <ul className="filter-lists">
          <li>
            <button className="invert-btn step1" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=all`}>ALL</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step2" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=BI/BX`}>BI/BX</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step3" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=GUI`}>GUI</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step4" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=UX/SI`}>UX/SI</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step5" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=BDX/BM`}>BDX</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step6" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=PRODUCT`}>PRODUCT</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step7" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=CONTENT/CAMPAIGN/PROMOTION/EXPERIENCE`}>CONTENT</DelayLink>
              </span>
            </button>
          </li>
          {/*
          <li>
            <button className="invert-btn step2" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=BI/BX`}>BI/BX</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step3" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=UI/UX`}>UI/UX</DelayLink>
              </span>
            </button>
          </li>
          <li>
            <button className="invert-btn step4" onClick={filterBtnToggle}>
              <span className="btn-wrap">
                <DelayLink to={`${pathname}?filter=SI/ETC`}>SI/etc.</DelayLink>
              </span>
            </button>
          </li>
          */}
          {/* filterArr.map((item, index) => (
            <li key={index}>
              <button className="invert-btn">
                <span className="btn-wrap">
                  <DelayLink to={`${pathname}?filter=${item}`}>{item}</DelayLink>
                </span>
              </button>
            </li>
        )) */}
        </ul>
      </div>
      <DelayLink to="/work" className="arrow-txt ani">
        <span>
          <span className="pd-font">(</span>All <i className="pd-font">Project</i>
          <span className="arrow">
            <span className="step1"></span>
            <span className="step2"></span>
          </span>
          <span className="pd-font">)</span>
        </span>
      </DelayLink>
    </div>
  );
};

export default Category;
