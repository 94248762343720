import React, { useEffect, useState } from "react";
import "./archive.scss";
import { DelayLink } from "./../common/Header/DelayLink";
import Footer from "./../common/Footer/Footer";
import $ from "jquery";
import { useParams } from "react-router";
import axios from "axios";

const apiPath = "https://admin.thinkthingthank.com/api";

let moreViewCnt = 0;
let moreCnt = 0;

const Archive = () => {
  const [awardList, setAwardList] = useState([]);
  const [pressList, setPressList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [newsCnt, setNewsCnt] = useState([]);
  const [pressYearList, setPressYearList] = useState([]);

  const slideTogglePress = (e) => {
    $(e.currentTarget).next(".img-box").slideToggle(200).toggleClass("on");
  };

  useEffect(() => {
    const getPosts = async () => {
      const response = await axios.post(apiPath + "/getArchiveList");
      var data = response.data;

      setAwardList(data.awardRow);
      setPressList(data.pressRow);
      setNewsList(data.newsRow);
      setNewsCnt(data.newsCnt);
      setPressYearList(data.pressYearArr);

      moreCnt = Math.ceil(data.newsCnt / 6);

      if (moreCnt > 1) {
        moreViewCnt = 1;
      } else {
        moreViewCnt = 0;
        $(".moreBtn").hide();
      }
    };

    getPosts();
  }, []);

  const getPostsNews = async () => {
    const response = await axios.get(apiPath + "/getNewsAddList?cnt=" + moreViewCnt);

    var data = response.data;
    console.log(newsList);

    var mergeList = [];

    mergeList = newsList.concat(data.newsRow);

    setNewsList(mergeList);
    moreViewCnt++;

    if (moreCnt == moreViewCnt) {
      $(".moreBtn").hide();
    }
  };

  return (
    <div className="archive">
      <div className="scroll-container">
        <div className="sec1">
          <div className="inner">
            <h1 className="ani">
              <span>
                Arc<i className="pd-font">hi</i>ve
              </span>
            </h1>
          </div>
        </div>
        <div className="sec2">
          <div className="inner">
            <div className="year-lists award">
              <h2 className="sec-title line-top line-bottom ani">
                <span>
                  Aw<i className="pd-font">ard</i>
                </span>
              </h2>
              <ul>
                {awardList.map((item, index) => (
                  <li key={index}>
                    <h4 className="year">
                      <span className="txt ani">
                        <span>{item.year}</span>
                      </span>
                    </h4>
                    <ul className="title-lists">
                      <li>
                        <h4 className="title line-bottom ani">
                          <span>
                            <img src={`https://admin.thinkthingthank.com${item.icon_img}`} alt="" />
                            {item.title}
                          </span>
                        </h4>
                        <ul className="content-lists">
                          {item.contentArr.map((item2, index2) => (
                            <li key={index2} className="ani">
                              <span>{item2}</span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="sec3">
          <div className="inner">
            <div className="year-lists press">
              <h2 className="sec-title line-top line-bottom ani">
                <span>
                  P<i className="pd-font">re</i>ss
                </span>
              </h2>
              <ul>
                {pressYearList.map((item, index) => (
                  <li key={index}>
                    <h4 className="year">
                      <span className="txt ani">
                        <span>{item.year}</span>
                      </span>
                    </h4>
                    <ul className="title-lists">
                      <li>
                        <ul className="content-lists">
                          {pressList[index].map((item2, index2) => (
                            <li key={index2} className="line-bottom ani">
                              <button onClick={slideTogglePress}>
                                <span className="ko" dangerouslySetInnerHTML={{ __html: item2.title }}></span>
                              </button>
                              <div className="img-box" dangerouslySetInnerHTML={{ __html: item2.content }}></div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="sec4">
          <div className="inner">
            <h2 className="sec-title line-top line-bottom ani">
              <span>
                N<i className="pd-font">ews</i>
              </span>
            </h2>
            <div className="news-lists">
              <ul>
                {newsList.map((item, index) => (
                  <li key={index}>
                    <a href={`${item.link_url}`} target="_blank">
                      <div className="img-box ani">
                        <img src={`https://admin.thinkthingthank.com${item.thumb_img}`} alt="" />
                      </div>
                      <div className="text-box ani">
                        <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="view-more ani moreBtn">
              <button onClick={getPostsNews}>
                <span className="pd-font">(</span>
                View m<i className="pd-font">o</i>re
                <span className="pd-font">)</span>
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Archive;
