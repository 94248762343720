import React, { useCallback, useEffect, useState } from "react";
import "./work.scss";
import "../../sass/projectList.scss";
import Category from "../common/Category/Category";
import ProjectLists from "../common/ProjectLists/ProjectLists";
import Footer from "../common/Footer/Footer";

const Work = () => {
  return (
    <div className="work">
      <div className="scroll-container">
        <div className="sec1">
          <div className="inner">
            <h1 className="step1 ani">
              <span>
                MAKING
                <img src="./images/work_sec1_img1.png" alt="" className="img1" />
                GOOD
              </span>
            </h1>
            <h1 className="step2 ani">
              <span>
                Move
                <img src="./images/work_sec1_img2.png" alt="" className="img2" />
                Ment
                <div className="video1">
                  <div className="video">
                    <div style={{ padding: "45.83% 0 0 0", position: "relative" }}>
                      <iframe
                        src="https://player.vimeo.com/video/767208325?h=bf8ba6fbc7&controls=0&autopause=0&background=1"
                        title="work_movement"
                        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </span>
            </h1>
            <div className="p-box pc ani">
              <p>
                <span>띵띵땡은 사람과 사물을 관찰하고 이에 따른 스토리 디자인을 지향합니다.</span>
              </p>
              <p>
                <span>이런 과정을 통해서 한개, 한개의 프로젝트가 세상에 나올 때마다 조금씩이지만</span>
              </p>
              <p>
                <span>세상은 재미있는 이야기들로 채워져 나갈 수 있다고 믿습니다.</span>
              </p>
              <p>
                <span>상상만 해도 얼마나 즐거운 일인가요!</span>
              </p>
            </div>
            <div className="p-box mo ani">
              <p>
                <span>띵띵땡은 사람과 사물을 관찰하고 이에 따른 스토리</span>
              </p>
              <p>
                <span>디자인을 지향합니다. 이런 과정을 통해서 한개, 한개의</span>
              </p>
              <p>
                <span>프로젝트가 세상에 나올 때마다 조금씩이지만 세상은</span>
              </p>
              <p>
                <span>재미있는 이야기들로 채워져 나갈 수 있다고 믿습니다.</span>
              </p>
              <p>
                <span>상상만 해도 얼마나 즐거운 일인가요!</span>
              </p>
            </div>
          </div>
        </div>
        <div className="sec2">
          <Category category=""/>
        </div>
        <div className="sec3">
          <div className="inner">
            <ProjectLists category="" />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Work;
