import React from "react";
import { Helmet } from "react-helmet-async";
import Main from "./../components/main/Main";

const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>thing think thank</title>
      </Helmet>
      <Main />
    </>
  );
};

export default MainPage;
