import "./main.scss";
import $ from "jquery";
import Footer from "./../common/Footer/Footer";
import DelayLink from "../common/Header/DelayLink";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import { Autoplay, Swiper as RealSwiper } from "swiper";

const Main = () => {
  const params1 = {
    slidesPerView: "auto",
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
    },
    speed: 17000,
  };
  const params2 = {
    slidesPerView: "auto",
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
    },
    speed: 18000,
  };
  const params3 = {
    slidesPerView: "auto",
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
    },
    speed: 20000,
  };
  const onMouseEnter = (e) => {
    const i = $(e.target).parents(".circle").index() + 1;
    $(".circle" + i).addClass("on");
  };
  const onMouseLeave = (e) => {
    $(".main .bg").removeClass("on");
  };

  RealSwiper.use([Autoplay]);

  return (
    <div className="main">
      <div className="bg circle1">
        <div className="video">
          <iframe
            src="https://player.vimeo.com/video/767207677?h=e2360c7d17&controls=0&autopause=0&background=1"
            title="main_bg_creative"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="bg circle2">
        <div className="video">
          <iframe
            src="https://player.vimeo.com/video/767207757?h=3250432001&controls=0&autopause=0&background=1"
            title="main_bg_business"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="bg circle3">
        <div className="video">
          <iframe
            src="https://player.vimeo.com/video/767208035?h=ac27f4305d&controls=0&autopause=0&background=1"
            title="main_bg_social"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="content">
        <div className="react-swiper linear pc">
          <Swiper {...params1}>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
          </Swiper>
        </div>
        <div className="react-swiper linear tablet">
          <Swiper {...params2}>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
          </Swiper>
        </div>
        <div className="react-swiper linear mo">
          <Swiper {...params3}>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
            <div>
              <p className="marquee">
                think thing thank <span>Creative Group</span>
              </p>
            </div>
          </Swiper>
        </div>
        <div className="circle-box">
          <div className="circle-box-wrap">
            <div className="circle" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="circle-wrap">
                <DelayLink to="/work-creative" className="text-box">
                  <span className="top-txt">
                    (Cr<i>ea</i>tive)
                  </span>
                  <span className="bottom-txt">think</span>
                </DelayLink>
              </div>
            </div>
            <div className="circle" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="circle-wrap">
                <DelayLink to="/work-business" className="text-box">
                  <span className="top-txt">
                    (Busi<i>ne</i>ss)
                  </span>
                  <span className="bottom-txt">thing</span>
                </DelayLink>
              </div>
            </div>
            <div className="circle" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="circle-wrap">
                <DelayLink to="/work-social" className="text-box">
                  <span className="top-txt">
                    (<i>So</i>cial)
                  </span>
                  <span className="bottom-txt">thank</span>
                </DelayLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
