import React from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";

export const DelayLink = ({ to, children, className }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const clickLink = (e) => {
    e.preventDefault();

    if (className === "logo-link" && pathname === "/") return;

    $(".cursor").removeClass("hover hidden");
    $("header").removeClass("active");

    if (!$(e.target).parents().hasClass("category-lists")) {
      $(".routes").removeClass("black-page");
    }

    if (className === "view") {
      $(".passing-page").addClass("active");
    } else {
      $(".routes").addClass("fadeOut active");
      $(".ani, .ani2").removeClass("active");
    }

    setTimeout(
      () => {
        navigate(to);

        window.scroll(0, 0);
        $(".routes").removeClass("fadeOut active");
        $(".routes").addClass("fadeIn active");

        setTimeout(() => {
          $(".routes").removeClass("fadeIn active");
          if ($(e.target).parents().hasClass("category-lists")) {
            $(".routes").addClass("black-page");
          }
        }, 300);

        setTimeout(() => {
          $(".passing-page").removeClass("active");
        }, 1000);
      },
      className === "view" ? 320 : 900
    );
  };

  return (
    <NavLink to={to} onClick={clickLink} className={className}>
      {children}
    </NavLink>
  );
};

export default DelayLink;
