import React from "react";
import { Helmet } from "react-helmet-async";
import WorkBusiness from "../components/workBusiness/WorkBusiness";

const WorkBusinessPage = () => {
  return (
    <>
      <Helmet>
        <title>Work Business | thing think thank</title>
      </Helmet>
      <WorkBusiness />
    </>
  );
};

export default WorkBusinessPage;
