import React from "react";
import { Helmet } from "react-helmet-async";
import WorkSocial from "../components/workSocial/WorkSocial";

const WorkSocialPage = () => {
  return (
    <>
      <Helmet>
        <title>Work Social | thing think thank</title>
      </Helmet>
      <WorkSocial />
    </>
  );
};

export default WorkSocialPage;
